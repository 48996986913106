import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Loader from '../../../components/loader';
import { AddUpdateFileInformation } from '../../../interface/albums';
import { APIResponseEntity } from '../../../interface/api-responce';
import { FileDetails } from '../../../interface/file-details';
import { TagsDDL } from '../../../interface/tags';
import { APICreateUpdateTag, APIGetAlbumLocation, APIGetTagDDL, APIUpdateTagsAndFileDisplayName, APIUploadPhoto } from '../../../service/api-service';
import { checkValidInputOnKeyDown, fetchFileDetails, removeSpaceOnFirstCharacter } from '../../../service/shared.service';
import { albumsFileExtension, AllowedExcelExtension, AllowedPDFExtension, AllowedPPTExtension, AllowedVideoExtension, AllowedWordExtension, FileExtensionInAlbum, toastOptObj } from '../../../utils/constants';
import { IsNullOrEmptyArray, IsNullOrUndefined, IsStringNullEmptyOrUndefined } from '../../../utils/null-check';
import { ALPHABET_NUMBER_SPACE_PATTERN_DOT_ALLOW, FILENAME_PATTERN } from '../../../utils/pattern';

function AddUpdateFile(
    { isAddUpdateFileDialogVisible, closeDialogCB, folderId, fileName, filePath, displayName, tags, photoId }
        :
        { isAddUpdateFileDialogVisible: boolean; closeDialogCB: (value: boolean) => void; folderId: string; fileName: string; filePath?: string; displayName?: string; tags?: string[]; photoId?: string; }
) {
    const [selectedFiles, setSelectedFiles] = useState<AddUpdateFileInformation[]>([]);
    const [selectedFileTag, setSelectedFileTag] = useState<string[]>([]);
    const [tagsDDL, setTagsDDL] = useState<TagsDDL[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [albumLocation, setAlbumLocation] = useState<string>("")
    const [displayFileName, setDisplayFileName] = useState<string>("")
    const [valueTagName, setValueTagName] = useState<string>("");
    const [isOpenAddTag, setIsOpenAddTag] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const paramAlbumId: string | null = params.get('albumId');

    useEffect(() => {
        if (!IsNullOrUndefined(folderId) && !IsStringNullEmptyOrUndefined(folderId)) {
            const fetchDefaultData = async (): Promise<void> => {
                // await fetchTags();
                await fetchLocation(folderId);
            };
            fetchDefaultData();
        }

        const fetchTagsDropDown = async (): Promise<void> => {
            await fetchTags();
        }
        fetchTagsDropDown();


        if (tags && !IsNullOrUndefined(tags) && !IsNullOrEmptyArray(tags)) {
            setSelectedFileTag(tags)
        }

        if (displayName && !IsNullOrUndefined(displayName)) {
            setDisplayFileName(displayName)
        }

    }, [isAddUpdateFileDialogVisible]);

    const handleFileDelete = (indexToRemove: number) => {
        const updatedSelectedFiles: AddUpdateFileInformation[] | [] = selectedFiles.filter(
            (_, index) => index !== indexToRemove
        );
        setSelectedFiles(updatedSelectedFiles);
    };

    const handleCancleBtn = (): void => {
        closeDialogCB(true);
        setSelectedFiles([]);
        setSelectedFileTag([]);
        setIsOpenAddTag(false);
    };

    const onFilesUpload = (e: { files: any }) => {
        const files = e.files;

        if (!IsNullOrUndefined(files)) {
            const validFiles: AddUpdateFileInformation[] = [];
            files.forEach((file: File) => {
                const fileDetails: FileDetails | null = fetchFileDetails(file);

                if (fileDetails) {
                    const fileType: string = fileDetails.fileType.toLowerCase();
                    const fileSize: number = fileDetails.fileSize;
                    const displaySize: number = fileDetails.displayFileSize;
                    const maxFileSize: number = 30 * 1024 * 1024; // 30 MB


                    if (fileSize > maxFileSize) {
                        toast.error(`File ${file.name} exceeds the max size of 30 MB`, {
                            ...toastOptObj,
                        });
                        return;
                    }

                    if (FileExtensionInAlbum.includes(fileType)) {
                        validFiles.push({ fileName: file.name, fileSize: displaySize, file });
                    } else {
                        toast.error(`File ${file.name} has an unsupported type`, {
                            ...toastOptObj, className: 'toast-libary'
                        });
                    }
                }
            });
            if (validFiles.length + selectedFiles.length > 10) {
                toast.error(`You can only upload up to 10 files.`, {
                    ...toastOptObj,
                });
            }
            else {
                setSelectedFiles((prevFiles) => prevFiles.concat(validFiles));
            }
        }
    };

    const handleValidations = (): boolean => {
        return (
            IsNullOrEmptyArray(selectedFileTag) ||
            IsNullOrEmptyArray(selectedFiles) ||
            isOpenAddTag || isLoading
        );
    };

    const handleValidationsForEditFiles = (): boolean => {
        return (
            IsNullOrEmptyArray(selectedFileTag) ||
            IsStringNullEmptyOrUndefined(displayFileName) ||
            displayFileName.trim().length === 0 ||
            isOpenAddTag
        );
    };

    const handleAddBtn = async (): Promise<void> => {
        if (
            selectedFiles &&
            !IsNullOrUndefined(selectedFiles) &&
            !IsNullOrEmptyArray(selectedFileTag)
        ) {
            const formData = new FormData();

            selectedFiles.forEach((file: AddUpdateFileInformation) => {
                formData.append("photos", file.file);
            });
            setIsLoading(true)
            const resp: AxiosResponse<APIResponseEntity> =
                await APIUploadPhoto(selectedFileTag, folderId, formData);
            if (
                !resp ||
                (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))
            ) {
                setIsLoading(false);
                return;
            }
            const apiRespPayload: APIResponseEntity = resp.data;

            if (
                (apiRespPayload.statusCode !== 201 &&
                    apiRespPayload.statusCode !== 200) ||
                !apiRespPayload.data
            ) {
                toast.error(apiRespPayload.message || apiRespPayload.errorMsg, {
                    ...toastOptObj,
                });
                setIsLoading(false);
                return;
            }
            toast.success(apiRespPayload.message, { ...toastOptObj });
            setIsLoading(false)
            handleCancleBtn()
        }
    };

    const handleFilesOnClick = (url: string): void => {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleUpdateBtn = async (): Promise<void> => {
        if (
            selectedFiles &&
            !IsNullOrUndefined(selectedFiles) &&
            !IsNullOrEmptyArray(selectedFileTag)
        ) {

            setIsLoading(true)



            const resp: AxiosResponse<APIResponseEntity> =
                await APIUpdateTagsAndFileDisplayName({
                    tagIds: selectedFileTag,
                    photoId: photoId ?? "",
                    displayFileName: displayFileName ?? "",
                    albumbId: paramAlbumId ? paramAlbumId : ""
                });
            if (
                !resp ||
                (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))
            ) {
                setIsLoading(false);
                return;
            }
            const apiRespPayload: APIResponseEntity = resp.data;

            if (
                (apiRespPayload.statusCode !== 201 &&
                    apiRespPayload.statusCode !== 200) ||
                !apiRespPayload.data
            ) {
                toast.error(apiRespPayload.message || apiRespPayload.errorMsg, {
                    ...toastOptObj,
                });
                setIsLoading(false);
                return;
            }
            toast.success(apiRespPayload.message, { ...toastOptObj });
            setIsLoading(false)
            handleCancleBtn()
        }
    };

    const fetchTags = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetTagDDL();

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;

        if (axiosResp.statusCode !== 200 || !axiosResp.data) {
            toast.error(axiosResp.message || axiosResp.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        setTagsDDL(axiosResp.data.results)
        setIsLoading(false);
    };

    const fetchLocation = async (folderId: string): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetAlbumLocation(folderId);

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;
        console.log("axiosResp:- ", axiosResp)
        if (axiosResp.statusCode !== 200 || !axiosResp.data) {
            toast.error(axiosResp.message || axiosResp.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        setAlbumLocation(axiosResp.data.location)
        setIsLoading(false);
    };

    const handleRedirect = () => {
        navigate("/jb-library/tags");
    };

    const createUpdateTagName = async (): Promise<void> => {
        setIsLoading(true);

        const resp: AxiosResponse<APIResponseEntity> = await APICreateUpdateTag({ tagName: valueTagName, id: '' });

        if (!resp || (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))) {
            setIsLoading(false); return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if ((apiRespPayload.statusCode !== 201 && apiRespPayload.statusCode !== 200) || !apiRespPayload.data) {
            console.log(apiRespPayload);

            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        toast.success(apiRespPayload.message, { ...toastOptObj });
        setValueTagName('');
        setIsOpenAddTag(false);
        await fetchTags();
        setIsLoading(false);
    };

    const isTagNameValid = (): boolean => {
        return !(IsStringNullEmptyOrUndefined(valueTagName) || valueTagName.trim().length === 0);
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            {fileName === "Add files to album" ? <Dialog
                className='libraryModal'
                visible={isAddUpdateFileDialogVisible}
                style={{ width: "560px", height: "600px", borderRadius: "4px" }}
                onHide={handleCancleBtn}
                closable={false}
            >
                <img
                    className="albums-add-album"
                    src="assets/images/file-upload.svg"
                    alt="add-album"
                />
                <span
                    className="albums-add-album fw-bold mt-2"
                    style={{ fontSize: "18px", color: "black" }}
                >
                    {fileName}
                </span>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <label htmlFor="albumName" className="form-label libraryFontColor">
                            LOCATION
                        </label>
                        <InputText
                            id="libraryLocation"
                            value={albumLocation}
                            className="w-100"
                            style={{ height: "48px" }}
                            disabled={true}
                            readOnly={true}
                        />
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="thumbnail-box">
                            <img
                                className="mt-3"
                                src="assets/images/upload-file.svg"
                                alt="upload-file"
                            />
                            <label htmlFor="thumbnail" className="mt-3 form-label">
                                Drag the file here.
                            </label>
                            <FileUpload
                                id="thumbnail"
                                mode="basic"
                                name="demo[]"
                                onSelect={onFilesUpload}
                                auto
                                multiple
                                className="p-fileupload-custom"
                                chooseOptions={{
                                    label: "Select Files",
                                    icon: "",
                                    className: "mt-3 custom-select-file-btn",
                                }}
                            />
                        </div>
                        <span
                            className="mt-5"
                            style={{ color: "#909090", fontSize: "12px" }}
                        >
                            Allowed file types: jpg, jpeg, png, gif, webp, heic, ogg, ogv,
                            mp4, m4v, strm, pdf, docx, xlsx, csv, pptx.
                        </span>
                        <span className="d-block" style={{ color: "#909090", fontSize: "12px" }}>
                            Max File Size: 30MB
                        </span>

                        {!IsNullOrEmptyArray(selectedFiles) && (
                            <div className="mt-3 file-list">
                                {selectedFiles.map((item, index) => (
                                    <div className="card m-2" style={{ borderRadius: "5px" }}>
                                        <div
                                            className="card-body"
                                            style={{
                                                padding: "15px",
                                                fontSize: "0.9em",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {item.fileName}
                                            <div className="displayFileSize">{item.fileSize} MB</div>
                                            <img
                                                src="assets/images/file-remove.svg"
                                                alt="add-trash"
                                                onClick={() => handleFileDelete(index)}
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    position: "absolute",
                                                    right: "15px",
                                                    cursor: "pointer",
                                                    top: "20px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <Message
                            className="w-full justify-content-start me-2 mt-2"
                            severity="info"
                            content={
                                <>
                                    <div className="flex align-items-center">
                                        <div className="ml-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-info-circle-fill me-2"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                            </svg>
                                            A maximum of 10 images are allowed per upload
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </div>

                    <div className="col-md-12 mt-3">
                        <label className="form-label libraryFontColor">
                            TAG <sup className="mandatory">*</sup>
                        </label>
                        <div className="d-flex align-items-center">
                            {
                                !isOpenAddTag &&
                                <>
                                    <MultiSelect
                                        value={selectedFileTag}
                                        onChange={(e) => setSelectedFileTag(e.value)}
                                        options={tagsDDL}
                                        optionLabel="tagName"
                                        optionValue="tagId"
                                        placeholder="Select a Tag"
                                        style={{ width: "78%" }}
                                        filter
                                        showClear
                                        resetFilterOnHide
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-success ms-2"
                                        style={{ whiteSpace: "nowrap" }}
                                        onClick={() => setIsOpenAddTag(true)}
                                    >
                                        Add Tag
                                    </button>
                                </>
                            }
                            {
                                isOpenAddTag &&
                                <>
                                    <div className='col-7 me-2'>
                                        <input type="text" className="form-control form-control-lg"
                                            onKeyDown={(event: any) => {
                                                checkValidInputOnKeyDown(event, ALPHABET_NUMBER_SPACE_PATTERN_DOT_ALLOW);
                                                removeSpaceOnFirstCharacter(event);
                                            }} maxLength={25} name="tagName" value={valueTagName} onChange={(e) => setValueTagName(e.target.value)} />
                                    </div>
                                    <div className='col-5 d-flex px-2'>
                                        <button type="submit" className="btn btn-block btn-cancel w-100 me-2" onClick={() => { setIsOpenAddTag(false); setValueTagName('') }}>
                                            Close
                                        </button>
                                        <button type="submit" className="btn btn-block btn-primary w-100"
                                            onClick={() => createUpdateTagName()}
                                            disabled={!isTagNameValid()}>
                                            Save
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row mt-4 modalBtn">
                    <div className="col-md-6 d-flex justify-content-end">
                        <Button
                            label="Cancel"
                            onClick={() => {
                                closeDialogCB(true);
                                setSelectedFiles([]);
                                setSelectedFileTag([]);
                                setIsOpenAddTag(false);
                            }}
                            className="cancelBtn w-100"
                        />
                    </div>
                    <div className="col-md-6 d-flex justify-content-start">
                        <Button className="addBtn w-100"
                            label="Add"
                            onClick={handleAddBtn}
                            disabled={handleValidations()}
                        />
                    </div>
                </div>
            </Dialog> :
                <Dialog
                    className='libraryModal'
                    visible={isAddUpdateFileDialogVisible}
                    style={{ width: "560px", height: "600px", borderRadius: "4px" }}
                    onHide={handleCancleBtn}
                    closable={false}
                >
                    <img
                        className="albums-add-album mt-4"
                        src="assets/images/file-upload.svg"
                        alt="add-album"
                    />
                    <span
                        className="albums-add-album fw-bold mt-3"
                        style={{ fontSize: "18px", color: "black" }}
                    >
                        {fileName}
                    </span>
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <label htmlFor="albumName" className="form-label">
                                FILE NAME <sup className="mandatory">*</sup>
                            </label>
                            <InputText
                                id="albumName"
                                value={displayFileName}
                                className="p-inputtext-sm w-100"
                                style={{ height: "48px" }}
                                disabled={false}
                                onChange={(e) => setDisplayFileName(e.target.value)}
                                onKeyDown={(event: any) => {
                                    checkValidInputOnKeyDown(
                                        event,
                                        FILENAME_PATTERN
                                    );
                                    removeSpaceOnFirstCharacter(event);
                                }}
                                onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
                                    event.preventDefault();
                                    let pastedText = event.clipboardData.getData("text");
                                    pastedText = pastedText.replace(/[\/:*?"<>|%]/g, "");
                                    if (pastedText) {
                                        setDisplayFileName((prev) => prev + pastedText);
                                    }
                                }}
                            />
                        </div>

                        <div className="col-md-12 mt-3 " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <img src={filePath} alt={displayName} style={{ width: "100%", height: "370px" }} /> */}
                            {
                                (
                                    filePath && albumsFileExtension.includes(filePath.split(".").pop()?.toLowerCase() ?? "")
                                ) &&
                                <img src={filePath} alt={displayFileName} loading="lazy" style={{ width: "100%", height: "370px" }} />

                            }
                            {
                                filePath && AllowedVideoExtension.includes(filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                <video controls className="w-100 ">
                                    <source src={filePath} type={`video/${filePath.split(".").pop()?.toLowerCase()}`} />
                                    {/* Your browser does not support the video tag. */}
                                </video>

                            }
                            {
                                filePath && AllowedPDFExtension.includes(filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                <img src='assets/images/pdf-icon.svg' alt={displayFileName} loading="lazy" style={{ width: "50%", height: "370px" }} onClick={() => handleFilesOnClick(filePath)} />
                            }
                            {
                                filePath && AllowedWordExtension.includes(filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                <img src='assets/images/word-icon.svg' alt={displayFileName} loading="lazy" style={{ width: "50%", height: "370px" }} onClick={() => handleFilesOnClick(filePath)} />
                            }
                            {
                                filePath && AllowedExcelExtension.includes(filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                <img src='assets/images/excel-icon.svg' alt={displayFileName} loading="lazy" style={{ width: "50%", height: "370px" }} onClick={() => handleFilesOnClick(filePath)} />
                            }
                            {
                                filePath && AllowedPPTExtension.includes(filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                <img src='assets/images/ppt-icon.svg' alt={displayFileName} loading="lazy" style={{ width: "50%", height: "370px" }} onClick={() => handleFilesOnClick(filePath)} />
                            }
                        </div>

                        <div className="col-md-12 mt-3">
                            <label className="form-label">
                                TAG <sup className="mandatory">*</sup>
                            </label>
                            <div className="d-flex align-items-center">
                                {
                                    !isOpenAddTag && <>
                                        <MultiSelect
                                            value={selectedFileTag}
                                            onChange={(e) => setSelectedFileTag(e.value)}
                                            options={tagsDDL}
                                            optionLabel="tagName"
                                            optionValue="tagId"
                                            placeholder="Select a Tag"
                                            style={{ width: "78%" }}

                                            filter
                                            showClear
                                            resetFilterOnHide
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-success ms-2"
                                            style={{ whiteSpace: "nowrap" }}
                                            onClick={() => setIsOpenAddTag(true)}
                                        >
                                            Add Tag
                                        </button>
                                    </>
                                }
                                {
                                    isOpenAddTag &&
                                    <>
                                        <div className='col-7 me-2'>
                                            <input type="text" className="form-control form-control-lg"
                                                onKeyDown={(event: any) => {
                                                    checkValidInputOnKeyDown(event, ALPHABET_NUMBER_SPACE_PATTERN_DOT_ALLOW);
                                                    removeSpaceOnFirstCharacter(event);
                                                }} maxLength={25} name="tagName" value={valueTagName} onChange={(e) => setValueTagName(e.target.value)} />
                                        </div>
                                        <div className='col-5 d-flex px-2'>
                                            <button type="submit" className="btn btn-block btn-cancel w-100 me-2" onClick={() => { setIsOpenAddTag(false); setValueTagName('') }}>
                                                Close
                                            </button>
                                            <button type="submit" className="btn btn-block btn-primary w-100"
                                                onClick={() => createUpdateTagName()}
                                                disabled={!isTagNameValid()}>
                                                Save
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 modalBtn">
                        <div className="col-md-6 d-flex justify-content-end">
                            <Button
                                label="Cancel"
                                onClick={() => {
                                    closeDialogCB(true);
                                    setSelectedFiles([]);
                                    setSelectedFileTag([]);
                                    setIsOpenAddTag(false);
                                }}
                                className="cancelBtn w-100"
                            />
                        </div>
                        <div className="col-md-6 d-flex justify-content-start">
                            <Button className="addBtn w-100"
                                label="Update"
                                onClick={handleUpdateBtn}
                                disabled={handleValidationsForEditFiles()}
                            />
                        </div>
                    </div>
                </Dialog>
            }
        </>
    )
}

export default AddUpdateFile
import React, { useEffect, useState } from 'react'
import { EventArchitectsEntity, GetAllCampaignReqEntity, GetAllEventArchitectReqEntity } from '../../../interface/campaign';
import { APIResponseEntity } from '../../../interface/api-responce';
import { AxiosResponse } from 'axios';
import { APIExportIndiaDesignArchitectEventList, APIFetchEventArchitectList, APIUpdateEventArchitect } from '../../../service/api-service';
import { toast } from 'react-toastify';
import { toastOptObj } from '../../../utils/constants';
import { PaginateReqEntity } from '../../../interface';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { IsNullOrEmptyArray, IsStringNullEmptyOrUndefined } from '../../../utils/null-check';
import PrimePaginator from '../../../components/prime-paginator';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { Checkbox } from 'primereact/checkbox';
import Loader from '../../../components/loader';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { downloadCsvFile } from '../../../service/shared.service';
import { InputText } from 'primereact/inputtext';

const IndiaDesignEventPage = () => {

    const [filterReq, setFilterReq] = useState<GetAllEventArchitectReqEntity>({
        pageSize: 10,
        pageNumber: 0,
        searchText: '',
        campaign: '',
        name: '',
        firmName: ""
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [checkArchitectList, setCheckArchitectList] = useState<string[] | []>([]);

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [isAllSelected, setIsAllSelected] = useState(false);

    const [isUpdated, setIsUpdated] = useState(false);

    const [eventArchitectList, setEventArchitectList] = useState<EventArchitectsEntity[] | []>([])

    const fetchEventArchitectList = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIFetchEventArchitectList({ ...filterReq, pageNumber: filterReq.pageNumber + 1 });
        setIsLoading(false);

        if (!resp || (resp && (resp.status !== 200 || !resp.data))) {
            setIsLoading(false); return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if (apiRespPayload.statusCode !== 200 || !apiRespPayload.data) {
            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        let uuids = apiRespPayload.data.data.filter((item: any) => item.isInvited).map((item: any) => item.uuid)
        setIsAllSelected(uuids.length === apiRespPayload.data.data.length);
        setEventArchitectList(apiRespPayload.data.data);
        setTotalPageCount(apiRespPayload.data.totalCount);
        setCheckArchitectList([]);

    }

    const handleUpdateData = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIUpdateEventArchitect(checkArchitectList);
        setIsLoading(false);

        if (!resp || (resp && (resp.status !== 200 || !resp.data))) {
            setIsLoading(false); return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if (apiRespPayload.statusCode !== 200 || !apiRespPayload.data) {
            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        toast.success(apiRespPayload.message, { ...toastOptObj });
        setIsUpdated((prev => !prev));
    }

    const filterTemplateCampaignName = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={filterReq.campaign}
                placeholder="Search by campaign name"
                onChange={(e) =>
                    setFilterReq((prev) => ({
                        ...prev,
                        campaign: e.target.value,
                        pageNumber: 0,
                    }))
                }
            />
        );
    };
    const filterTemplateName = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={filterReq.name}
                placeholder="Search by name"
                onChange={(e) =>
                    setFilterReq((prev) => ({
                        ...prev,
                        name: e.target.value,
                        pageNumber: 0,
                    }))
                }
            />
        );
    };
    const filterFirmName = (
        options: ColumnFilterElementTemplateOptions
    ) => {
        return (
            <InputText
                value={filterReq.firmName}
                placeholder="Search by FirmName"
                onChange={(e) =>
                    setFilterReq((prev) => ({
                        ...prev,
                        firmName: e.target.value,
                        pageNumber: 0,
                    }))
                }
            />
        );
    };

    const exportArchitectEventList = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIExportIndiaDesignArchitectEventList();
        setIsLoading(false);

        if (!resp || !resp.data || resp.status !== 200) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;

        if (
            !axiosResp ||
            !axiosResp.data ||
            !axiosResp.status ||
            axiosResp.statusCode !== 200
        ) {
            toast.error(axiosResp.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const excelUrl = axiosResp.data.xlsxUrl;
        if (!excelUrl || IsStringNullEmptyOrUndefined(excelUrl)) {
            toast.error("Excel not found", { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const fileName: string = excelUrl.split("/").pop();
        await downloadCsvFile(excelUrl, fileName);
    };

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            await fetchEventArchitectList();
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterReq, isUpdated]);

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setFilterReq((prev) => ({
            ...prev,
            pageSize: event.rows,
            pageNumber: event.page,
        }));
    };
    const actionBody = (item: EventArchitectsEntity) => {
        return <Checkbox checked={item.isInvited || checkArchitectList.some((el) => el === item.uuid)} disabled={item.isInvited} onChange={(e) => checkArchitectList.some((el) => el === item.uuid) ? setCheckArchitectList((prev) => prev.filter((el) => el !== item.uuid)) : setCheckArchitectList((prev) => [...prev, item.uuid])} />
    }
    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="content-wrapper">
                <div className='col-12'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-12 mb-md-0 mb-3">
                            <div className="card card-rounded">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <h4 className="card-title">India Design Event List</h4>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-6 text-md-end text-lg-end mt-md-0 ">
                                            <div className="d-flex gap-3 w-100 justify-content-end">
                                                {
                                                    !IsNullOrEmptyArray(checkArchitectList) && (
                                                        <button type="button" onClick={() => setShowConfirmDialog(true)} className="btn btn-block  btn-success btn-md font-weight-medium m-0">
                                                            Save Detail
                                                        </button>
                                                    )
                                                }
                                                <button type="button" onClick={exportArchitectEventList} className="btn btn-block  btn-success btn-md font-weight-medium m-0">
                                                    Export Excel
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                    <div className='col mt-3'>
                                        {
                                            <DataTable
                                                value={eventArchitectList}
                                                filterDisplay="row"
                                            >
                                                <Column body={actionBody} header={() => <Checkbox checked={(!IsNullOrEmptyArray(eventArchitectList) && checkArchitectList.length === eventArchitectList.length) || (!IsNullOrEmptyArray(eventArchitectList) && isAllSelected)} disabled={isAllSelected} onChange={(e) => {
                                                    setCheckArchitectList((prev) => e.checked ? eventArchitectList.map((item) => item.uuid) : [])
                                                }} />}></Column>
                                                <Column field="campaign" header="CAMPAIGN NAME" filterElement={filterTemplateCampaignName}
                                                    filter
                                                    showFilterMenu={false} ></Column>
                                                <Column field="name" header="NAME" filterElement={filterTemplateName}
                                                    filter
                                                    showFilterMenu={false}></Column>
                                                <Column field="emailAddress" header="EMAIL ADDRESS" ></Column>
                                                <Column field="mobilePhone" header="MOBILE" ></Column>
                                                <Column field="firmName" header="FIRMNAME" filterElement={filterFirmName}
                                                    filter
                                                    showFilterMenu={false} ></Column>
                                                <Column field="state" header="STATE" ></Column>
                                                <Column field="city" header="CITY" ></Column>
                                            </DataTable>
                                        }
                                    </div>

                                    {
                                        !IsNullOrEmptyArray(eventArchitectList) &&
                                        <PrimePaginator
                                            onPageChange={onPageChange}
                                            pageNumber={filterReq.pageNumber}
                                            pageSize={filterReq.pageSize}
                                            totalRecords={totalPageCount}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ConfirmDialog
                onHide={() => setShowConfirmDialog(false)}
                visible={showConfirmDialog}
                message={
                    <>
                        Has the invite been sent to the selected architect?
                    </>
                }
                header="Confirmation" icon="pi pi-exclamation-triangle"
                closeOnEscape={false}
                closable={false}
                acceptClassName="btn btn-block btn-primary btn-md" rejectClassName="text-muted border-white bg-white"
                acceptLabel="Yes" rejectLabel="No"
                accept={handleUpdateData}
            // reject={() => { }}
            />
        </>

    )
}

export default IndiaDesignEventPage
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  RibbonEnumEntity,
  StorageKeyEnum,
  UserProfileEnumEntity,
} from "../utils/enum";
import {
  IsNullOrEmptyArray,
  IsNullOrUndefined,
  IsStringNullEmptyOrUndefined,
} from "../utils/null-check";
import { AssignedModuleData, UserInfoEntity } from "../interface";
import { checkActionAuthorization, render30CharcterAfterDot } from "../service/shared.service";
import { getLocalStorageItem, removeLocalStorageItem } from "../service/storage-service";
import { AxiosResponse } from "axios";
import { APIResponseEntity } from "../interface/api-responce";
import {
  APICommericalHoldOrderDetails,
  APIGetRoleById,
  APIProductionHoldOrderDetails,
  APIRedRibbonFetchCounts,
} from "../service/api-service";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useRedRibbon } from "../store/redRibbon.store";
import { commericalHoldData, productionHoldData } from "../interface/red-ribbon";
import Loader from "./loader";
import moment from "moment";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { toastOptObj } from "../utils/constants";

function Header() {

  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserInfoEntity | null>(null);
  // const [solutionCount, setSolutionCount] = useState<number>(0);
  const [orderCount, setOrderCount] = useState<number>(0);
  const [isOrderBtnClicked, setIsOrderBtnClicked] = useState<boolean>(false);
  const [isSolutionBtnClicked, setIsSolutionBtnClicked] = useState<boolean>(false);
  const [isUserNameVisible, setIsUserNameVisible] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commercialHoldOrderDetails, setCommercialHoldOrderDetails] = useState<commericalHoldData[] | null>(null);
  const [productionHoldOrderDetails, setProductionHoldOrderDetails] = useState<productionHoldData[] | null>(null);
  const { setClose, isOpen, setOpen, isUpdate } = useRedRibbon();

  const [currentSlide, setCurrentSlide] = useState(1);

  const { pathname } = useLocation()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const userStr: string | null = localStorage.getItem(
        StorageKeyEnum.JB_GLASS_USER_INFO
      );


      if (userStr && !IsStringNullEmptyOrUndefined(userStr)) {

        const userInfo: UserInfoEntity = JSON.parse(userStr);
        setUserData(userInfo);
        const assignedModules: AssignedModuleData[] = userInfo.assignedModule;

        if (assignedModules && !IsNullOrEmptyArray(assignedModules)) {

          const foundModule: boolean = assignedModules.some(
            (item) => item.moduleName === RibbonEnumEntity.RED_RIBBON
          ) && assignedModules.some(
            (item) => item.moduleName === RibbonEnumEntity.COMMERCIAL_HOLD
          );

          if (foundModule) {

            setIsLoading(true)
            const resp: AxiosResponse<APIResponseEntity> =
              await APIRedRibbonFetchCounts();
            setIsLoading(false)
            if (resp && resp.status === 200 && resp.data && resp.data.status) {
              const apiResp: APIResponseEntity = resp.data;
              const orderCount: number = apiResp.data.orderCount;
              // const solutionCount: number = apiResp.data.solutionCount;

              if (orderCount === 0) {

                setClose();
              } else {

                // setSolutionCount(solutionCount);
                setOrderCount(orderCount);
              }
            }
            else {
              setClose();
            }
          } else {
            setClose();
          }
        }
        else {
          setClose();
        }
      }
      else {
        setClose();
      }
    };
    fetchData();
  }, [isUpdate, pathname]);

  const fetchUserNameLetter = (): string => {
    let letters = "";
    if (userData && !IsNullOrUndefined(userData)) {
      const userName: string = userData.userFullName;
      const userNameArr: string[] = userName.split(" ");
      const firstWord: string = userNameArr[0];
      const lastWord: string = userNameArr[userNameArr.length - 1];
      letters =
        firstWord.charAt(0).toUpperCase() + lastWord.charAt(0).toUpperCase();
    }

    return letters;
  };




  const handleOrderBtnClick = (): void => {
    setIsOrderBtnClicked(true)
    setIsSolutionBtnClicked(false)
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)
      const resp: AxiosResponse<APIResponseEntity> = await APICommericalHoldOrderDetails();
      setIsLoading(false)
      if (resp && resp.status === 200 && resp.data && resp.data.status) {
        const apiResp: APIResponseEntity = resp.data;
        const ordersArr: commericalHoldData[] = apiResp.data.orders;
        const userVisiblePermission: string = apiResp.data.userVisibleOrderPermission

        const filteredArray: commericalHoldData[] = ordersArr.map(({ orderNumber, projectName, commercialHoldDate, finalAmtWithDiscont, userName }, index) => ({
          srNo: index + 1,
          orderNumber,
          projectName,
          commercialHoldDate: moment(commercialHoldDate).format('DD/MM/YYYY'),
          finalAmtWithDiscont,
          userName,
        }));
        setCommercialHoldOrderDetails(filteredArray)

        if (!IsStringNullEmptyOrUndefined(userVisiblePermission) && userVisiblePermission === RibbonEnumEntity.SELF_PERMISSION) {
          setIsUserNameVisible(true)
        }
      }

    };
    fetchData();
  }

  // const handleSolutionBtnClicked = (): void =>{
  //   setIsOrderBtnClicked(false)
  //   setIsSolutionBtnClicked(true)
  //   setIsLoading(true)
  //   const fetchData = async (): Promise<void> => {

  //       const resp: AxiosResponse<APIResponseEntity> =   await APIProductionHoldOrderDetails();
  //       setIsLoading(false)
  //               if (resp && resp.status === 200 && resp.data && resp.data.status) {
  //                 const apiResp: APIResponseEntity = resp.data;
  //                 const solutionsArr:productionHoldData[] = apiResp.data.solutions;
  //                 const userVisiblePermission:string = apiResp.data.userVisibleOrderPermission

  //                 const filteredArray:productionHoldData[] = solutionsArr.map(({ orderNumber, projectName, solutionPrice, finalAmtWithDiscont, userName,solutionNo , notifyDate},index) => ({
  //                   srNo:index+1,
  //                   orderNumber,
  //                   projectName,
  //                   solutionPrice,
  //                   finalAmtWithDiscont,
  //                   userName,
  //                   solutionNo,
  //                   notifyDate: IsStringNullEmptyOrUndefined(notifyDate) ? "" : moment(notifyDate).format('DD/MM/YYYY')
  //                   // notifyDate:moment(notifyDate).format('DD/MM/YYYY'),
  //                 }));   
  //                 setProductionHoldOrderDetails(filteredArray)                  
  //                 if(!IsStringNullEmptyOrUndefined(userVisiblePermission) && userVisiblePermission===RibbonEnumEntity.SELF_PERMISSION){
  //                    setIsUserNameVisible(true)
  //                 }
  //               }

  //         };
  //         fetchData();

  // }

  const handleCloseBtn = (): void => {
    setIsOrderBtnClicked(false)
    setIsSolutionBtnClicked(false)
  }

  const toggleBodyClass = (): void => {
    document.body.classList.toggle("mobieNavigation");
  };

  const settings = {
    beforeChange: (current: number, next: number) => {
      console.log(next);
      setCurrentSlide(next + 1);
    },
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    vertical: true,
    verticalSwiping: true,
  };

  const handleCopyLink = (): void => {
    const userDetail: any = getLocalStorageItem(StorageKeyEnum.JB_GLASS_USER_INFO)
    let link = `${process.env.REACT_APP_WEBSITE_URL}/india-design-invite/${JSON.parse(userDetail)?._id}/`;
    navigator.clipboard.writeText(link)
    toast.success("Link copied successfully.", { ...toastOptObj })
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">

        {isOpen && (orderCount != 0) && (
          <>
            <div className="orderDueHldr">
              <span>
                You have  <span className="ordercount" onClick={handleOrderBtnClick}>
                  {orderCount} commercial hold projects.
                </span>

                {/* You have {orderCount} commercial hold projects ({solutionCount} solutions). */}
              </span>

              {/* <div>
  <span style={{ cursor: 'pointer'  }} onClick={handleOrderBtnClick}>
    View Projects
  </span>
  <span style={{ cursor: 'pointer'  }} onClick={handleSolutionBtnClicked}>
  View Solutions
  </span>
</div> */}
              <a
                onClick={setClose}
                className="bi bi-x closeMain hover-cursor"
              ></a>
            </div>
            {/* <div className="sliderWrapper">
              <div className="counter">{`${currentSlide} / 6`}</div>
              <i className="bi bi-x-circle close"></i>
              <Slider {...settings}>
                {
                  array.map((items: any) => (
                    <div>
                      <div className="slideTxt">{items.name}</div>
                    </div>
                  ))
                }
              </Slider>
            </div> */}
          </>
        )}
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
          <div className="me-3">
            <button className="sidebar-favicon-logo" type="button">
              <img src="assets/images/favicon.png" alt="jb-glass" />
            </button>
          </div>
          <div>
            <a className="navbar-brand brand-logo" href="index.html">
              <img src="assets/images/logo.png" alt="logo" />
            </a>
            <a className="navbar-brand brand-logo-mini" href="index.html">
              <img src="assets/images/logo.png" alt="logo" />
            </a>
          </div>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-top">
          <ul className="navbar-nav">
            <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
              <h1 className="welcome-text">
                Hello,&nbsp;
                <span className="text-black fw-bold">
                  {userData && render30CharcterAfterDot(userData.userFullName)}
                </span>
                <small>&nbsp;( {userData && userData.roleName} ) </small>
              </h1>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto">
            {/* <li className="nav-item">
                            <form className="search-form" action="#">
                                <i className="icon-search"></i>
                                <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
                            </form>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link count-indicator cursor-pointer" id="notificationDropdown" data-bs-toggle="dropdown">
                                <i className="icon-message icon-lg"></i>
                            </span>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="notificationDropdown">
                                <span className="cursor-pointer dropdown-item py-3 border-bottom">
                                    <p className="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                                    <span className="badge badge-pill badge-primary float-right">View all</span>
                                </span>
                                <span className="dropdown-item preview-item py-3 cursor-pointer">
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-alert m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject fw-normal text-dark mb-1">Application Error</h6>
                                        <p className="fw-light small-text mb-0"> Just now </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item py-3 cursor-pointer">
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-settings m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject fw-normal text-dark mb-1">Settings</h6>
                                        <p className="fw-light small-text mb-0"> Private message </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item py-3 cursor-pointer">
                                    <div className="preview-thumbnail">
                                        <i className="mdi mdi-airballoon m-auto text-primary"></i>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject fw-normal text-dark mb-1">New user registration</h6>
                                        <p className="fw-light small-text mb-0"> 2 days ago </p>
                                    </div>
                                </span>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <span className="nav-link count-indicator" id="countDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="icon-notification"></i>
                                <span className="count"></span>
                            </span>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="countDropdown">
                                <span className="dropdown-item py-3">
                                    <p className="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
                                    <span className="badge badge-pill badge-primary float-right">View all</span>
                                </span>
                                <div className="dropdown-divider"></div>
                                <span className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="assets/images/faces/face10.jpg" alt="img" className="img-sm profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow py-2">
                                        <p className="preview-subject ellipsis font-weight-medium text-dark">Marian Garner </p>
                                        <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="assets/images/faces/face12.jpg" alt="img" className="img-sm profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow py-2">
                                        <p className="preview-subject ellipsis font-weight-medium text-dark">David Grey </p>
                                        <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
                                    </div>
                                </span>
                                <span className="dropdown-item preview-item">
                                    <div className="preview-thumbnail">
                                        <img src="assets/images/faces/face1.jpg" alt="img" className="img-sm profile-pic" />
                                    </div>
                                    <div className="preview-item-content flex-grow py-2">
                                        <p className="preview-subject ellipsis font-weight-medium text-dark">Travis Jenkins </p>
                                        <p className="fw-light small-text mb-0"> The meeting is cancelled </p>
                                    </div>
                                </span>
                            </div>
                        </li> */}
            <li className="nav-item dropdown d-none d-lg-block user-dropdown">
              <div className="d-flex gap-4 align-items-center">
                {
                  checkActionAuthorization("upgrade", "Access To Copy Link For India Design Form") && (
                    <div>
                      <button type="button" onClick={handleCopyLink} className="btn btn-block  btn-success btn-md font-weight-medium m-0 btn-sm" style={{ borderRadius: 5 }}>
                        Copy ID 2025 Invite Link
                      </button>
                    </div>
                  )
                }
                <div className="w-full">
                  <span
                    className="nav-link"
                    id="UserDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="name-circle"> {fetchUserNameLetter()} </span>
                  </span>
                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="UserDropdown"
                  >
                    <div className="dropdown-Header text-center p-2">
                      <span
                        className="name-circle"
                        style={{ margin: "0.5rem auto 0 auto" }}
                      >
                        {" "}
                        {fetchUserNameLetter()}{" "}
                      </span>

                      <p className="mb-1 mt-3 font-weight-semibold">
                        {userData &&
                          render30CharcterAfterDot(userData.userFullName)}
                      </p>
                      <p className="fw-light text-muted mb-0">
                        {userData &&
                          render30CharcterAfterDot(userData.emailAddress)}
                      </p>
                    </div>
                    <span className="dropdown-item">
                      <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>
                      My Profile
                      {/* <span className="badge badge-pill badge-danger">1</span> */}
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={() => navigate("/user/change-password")}
                    >
                      <img
                        src="assets/images/reset-password.svg"
                        alt="Reset Password"
                        className="me-2"
                      />
                      Change Password
                    </span>
                    <span
                      className="dropdown-item"
                      onClick={() => {
                        removeLocalStorageItem(StorageKeyEnum.JB_GLASS_AUTH_TOKEN);
                        removeLocalStorageItem(StorageKeyEnum.JB_GLASS_USER_INFO);
                        setOpen()
                        navigate("/");
                      }}
                    >
                      <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                      Sign Out
                    </span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          {/* <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button">
          </button> */}
          <span onClick={toggleBodyClass} className="mdi mdi-menu"></span>
        </div>

      </nav>

      {isOrderBtnClicked &&
        <Dialog
          className="trafficLightModel"
          header="Commerical Hold Projects"
          visible={true}
          style={{ width: '63vw', overflow: 'hidden' }}
          onHide={handleCloseBtn}
          draggable={false}
          resizable={false}
          modal={true}
          blockScroll={true}

        >
          {commercialHoldOrderDetails && (
            <DataTable
              value={commercialHoldOrderDetails}
              scrollable
              className="architectsTable"
              scrollHeight="430px"
              style={{ border: "1px solid #ccc" }}
            >
              <Column field="srNo" header="Sr. No" />
              <Column field="orderNumber" header="Order Number" />
              <Column field="projectName" header="Project Name" />
              <Column field="commercialHoldDate" header="Commercial Hold Date" />
              <Column field="finalAmtWithDiscont" header="Total Amount" />
              {
                !isUserNameVisible && <Column field="userName" header="User Name" />
              }

            </DataTable>
          )}

        </Dialog>
      }

      {
        isSolutionBtnClicked &&
        <Dialog
          className="trafficLightModel"
          header="Production Hold Solutions"
          visible={true}
          style={{ width: '73vw', overflow: 'hidden' }}
          onHide={handleCloseBtn}
          draggable={false}
          resizable={false}

        >
          {productionHoldOrderDetails && (
            <DataTable
              value={productionHoldOrderDetails}
              scrollable
              scrollHeight="430px"
              className="architectsTable"
              style={{ border: "1px solid #ccc" }}
            >
              <Column field="srNo" header="Sr. No" />
              <Column field="orderNumber" header="Order Number" />
              <Column field="projectName" header="Project Name" />
              <Column field="finalAmtWithDiscont" header="Total Amount" />
              <Column field="solutionPrice" header="Solution Price" />
              <Column field="solutionNo" header="Location No" />
              <Column field="notifyDate" header="Hold Since/notifyDate" />
              {
                !isUserNameVisible && <Column field="userName" header="User Name" />
              }
            </DataTable>
          )}

        </Dialog>
      }
    </>
  );
}

export default Header;
/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosResponse } from 'axios';
import JSZip from 'jszip';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../components/loader';
import PrimePaginator from '../../../components/prime-paginator';
import { AlbumsDDL } from '../../../interface/albums';
import { APIResponseEntity } from '../../../interface/api-responce';
import { albumLocationDetails, FilterReq, GetPhotosAPIResEntity, ImageNameAndURL } from '../../../interface/photos-grid';
import { TagsDDL } from '../../../interface/tags';
import { APICreateUpdateTag, APIDeletePhoto, APIGetAlbumLocation, APIGetAlbumPhotos, APIGetAlbumsDDL, APIGetTagDDL, APIGetTagDetails, APIMoveAlbumsImages, APIShowHidePhoto, APIUpdateTagsOfMultiplePhotos } from '../../../service/api-service';
import { checkActionAuthorization, checkValidInputOnKeyDown, getFileFromURL, getThumnailImgFor, removeSpaceOnFirstCharacter, scrollTop } from '../../../service/shared.service';
import { albumsFileExtension, AllowedExcelExtension, AllowedPDFExtension, AllowedPPTExtension, AllowedVideoExtension, AllowedWordExtension, toastOptObj } from '../../../utils/constants';
import { BulkActionsEntity } from '../../../utils/enum';
import { IsNullOrEmptyArray, IsNullOrUndefined, IsStringNullEmptyOrUndefined } from '../../../utils/null-check';
import { ALPHABET_NUMBER_SPACE_PATTERN_DOT_ALLOW } from '../../../utils/pattern';
import AddUpdateFile from '../albums/add-update-file';
import "../jb-library.css";

export default function Photos() {

    const location = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);
    const paramAlbumId: string | null = params.get('albumId');
    const tagId: string | null = params.get('tagId');
    const currentVideoRef = useRef<HTMLVideoElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isTagUpdateDialogVisible, setIsTagUpdateDialogVisible] = useState<boolean>(false);

    const [isMoveImagesDialogVisible, setIsMoveImagesDialogVisible] = useState<boolean>(false);

    const [albumLocation, setAlbumLocation] = useState<albumLocationDetails[]>([]);

    const [tagName, setTagName] = useState<string>("");

    const [images, setImages] = useState<GetPhotosAPIResEntity[] | []>([]);

    const [tagsDDL, setTagsDDL] = useState<TagsDDL[]>([]);
    const [albumsDDL, setAlbumsDDL] = useState<AlbumsDDL[]>([])

    const [selectedTag, setSelectedTag] = useState<string[]>([]);

    const [selectedAlbum, setSelectedAlbum] = useState<AlbumsDDL | null>(null);

    const [modelActiveImgIndex, setModelActiveImgIndex] = useState<number>(-1);

    const [deletePhotoId, setDeletePhotoId] = useState<string>("");
    const [hideShowDeletePhotoConfirmation, setHideShowDeletePhotoConfirmation] = useState<boolean>(false);

    const [CheckedAll, setCheckedAll] = useState<boolean>(false);

    const [totalPageCount, setTotalPageCount] = useState<number>(0);

    const [isAddUpdateFileDialogVisible, setIsAddUpdateFileDialogVisible] = useState(false);

    const [displayName, setDisplayName] = useState<string>("")

    const [filePath, setFilePath] = useState<string>("")

    const [tags, setTags] = useState<string[]>([])

    const [photoId, setPhotoId] = useState<string>("");

    const [filterReq, setFilterReq] = useState<FilterReq>({
        pageSize: 10,
        pageNumber: 0,
        APICallCount: 0,
    });

    const [valueTagName, setValueTagName] = useState<string>("");
    const [isOpenAddTag, setIsOpenAddTag] = useState<boolean>(false);

    const handleVideoPlay = (event: React.SyntheticEvent<HTMLVideoElement>): void => {

        if (currentVideoRef.current && currentVideoRef.current !== event.currentTarget) {
            currentVideoRef.current.pause();
        }
        currentVideoRef.current = event.currentTarget;
    };

    useEffect(() => {

        const fetchData = async (): Promise<void> => {
            if ((paramAlbumId && !IsNullOrUndefined(paramAlbumId) && !IsStringNullEmptyOrUndefined(paramAlbumId))) {
                await fetchAlbumLocation();
            }
            if ((tagId && !IsNullOrUndefined(tagId) && !IsStringNullEmptyOrUndefined(tagId))) {
                await fetchTagDetails();
            }
            await fetchAlbumPhotos();
            const scrollableDiv: HTMLElement | null = document.getElementById('scrollable-div');
            if (scrollableDiv && !IsNullOrUndefined(scrollableDiv)) {
                scrollableDiv.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
            }
        };

        if ((!paramAlbumId || IsNullOrUndefined(paramAlbumId) || IsStringNullEmptyOrUndefined(paramAlbumId)) && ((!tagId) || IsNullOrUndefined(tagId) || IsStringNullEmptyOrUndefined(tagId))) {
            navigate("/jb-library/albums");
            return;
        }

        if ((paramAlbumId && !IsNullOrUndefined(paramAlbumId) && !IsStringNullEmptyOrUndefined(paramAlbumId)) || (tagId && !IsNullOrUndefined(tagId) && !IsStringNullEmptyOrUndefined(tagId))) {
            fetchData();
        }
    }, [paramAlbumId, tagId, isAddUpdateFileDialogVisible, filterReq]);

    useEffect(() => {
        const updatedData = images.map(v => ({ ...v, isChecked: CheckedAll }));
        setImages(updatedData);
    }, [CheckedAll]);

    useEffect(() => {
        const modalElement = document.getElementById(
            "photosGalleryModel"
        ) as HTMLElement | null;

        if (modalElement) {
            modalElement.addEventListener("hidden.bs.modal", () => {
                resetCarousel();
            });

            return () => {
                modalElement.removeEventListener("hidden.bs.modal", resetCarousel);
            };
        }
    }, []);


    const fetchAlbumLocation = async (): Promise<void> => {
        if (!paramAlbumId) { return }

        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetAlbumLocation(paramAlbumId);

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data && resp.data.message ? resp.data.message : 'Something went wrong.', { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;

        if (axiosResp.statusCode !== 200 || !axiosResp.data) {
            toast.error(axiosResp.message || axiosResp.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        setTagName('')
        let albumLocation: string[] = axiosResp.data.location.split(" / ");
        let albumDetails: albumLocationDetails[] = albumLocation.map((item, index) => ({
            location: item,
            id: axiosResp.data.ids[index]
        }))
        setAlbumLocation(albumDetails);
        setIsLoading(false);
    };

    const fetchTagDetails = async (): Promise<void> => {
        if (!tagId) { return }

        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetTagDetails(tagId);

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data && resp.data.message ? resp.data.message : 'Something went wrong.', { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;

        if (axiosResp.statusCode !== 200 || !axiosResp.data) {
            toast.error(axiosResp.message || axiosResp.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        setTagName(axiosResp.data.data.tagName)
        setAlbumLocation([]);
        setIsLoading(false);
    };

    const fetchAlbumPhotos = async (showLoader: boolean = true): Promise<void> => {

        setTotalPageCount(0);
        setImages([]);

        let albumid: string = (paramAlbumId && !IsNullOrUndefined(paramAlbumId) && !IsStringNullEmptyOrUndefined(paramAlbumId)) ? paramAlbumId : '';
        let tagid: string = (tagId && !IsNullOrUndefined(tagId) && !IsStringNullEmptyOrUndefined(tagId)) ? tagId : '';
        setIsLoading(showLoader);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetAlbumPhotos(albumid, tagid, filterReq.pageNumber, filterReq.pageSize);

        if (!resp || (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))) {
            setIsLoading(false);
            return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if (apiRespPayload.statusCode !== 200 || !apiRespPayload.data) {
            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, {
                ...toastOptObj,
            });
            setIsLoading(false);
            return;
        }

        let data: GetPhotosAPIResEntity[] | [] = apiRespPayload.data.data;
        if (!IsNullOrEmptyArray(data)) {
            data = data.map(v => ({ ...v, isChecked: false, bucketThumbnailImagePath: getThumnailImgFor('photos', v.filePath) }));
        }
        setTotalPageCount(apiRespPayload.data.totalCount);
        setImages(data);
        if (IsNullOrEmptyArray(data) && filterReq.pageNumber !== 0) {
            setFilterReq({ ...filterReq, pageNumber: filterReq.pageNumber - 1 });
        }
        console.log(data);
        scrollTop();
        setIsLoading(false);
    };

    const hideShowPhoto = async (photoId: string): Promise<void> => {

        setIsLoading(true);
        setCheckedAll(false);
        const resp: AxiosResponse<APIResponseEntity> = await APIShowHidePhoto({ photoId });

        if (!resp || (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))) {
            setIsLoading(false);
            return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if (apiRespPayload.statusCode !== 200 || !apiRespPayload.data) {
            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, {
                ...toastOptObj,
            });
            setIsLoading(false);
            return;
        }

        toast.success(apiRespPayload.message, { ...toastOptObj });
        await fetchAlbumPhotos(false);
        setIsLoading(false);
    };

    const deletePhoto = async (): Promise<void> => {

        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIDeletePhoto(deletePhotoId);

        if (!resp || (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))) {
            setIsLoading(false);
            return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if (apiRespPayload.statusCode !== 200 || !apiRespPayload.data) {
            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, {
                ...toastOptObj,
            });
            setIsLoading(false);
            return;
        }

        toast.success(apiRespPayload.message, { ...toastOptObj });
        await fetchAlbumPhotos(false);
        setIsLoading(false);
    };

    const downloadPhoto = async (filePath: string, filename: string): Promise<void> => {
        const image = await fetch(filePath, { cache: "no-cache", })
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
        const link = document.createElement('a')
        link.href = imageURL;
        let filetype: string = (filePath.split('.').pop() || "jpeg");
        link.download = `${filename}.${filetype}` || 'image.jpeg';
        // link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const sharePhoto = async (filePath: string, displayFileName: string): Promise<void> => {
        try {
            const file = await getFileFromURL(filePath, displayFileName);

            if (!navigator.canShare || !navigator.canShare({ files: [file] })) {
                toast.error("This device/browser does not support file sharing.", { ...toastOptObj });
                return;
            }
            await navigator.share({
                files: [file],
            });
            console.log("Files shared successfully!");
        } catch (error) {
            toast.error("Error occurs during sharing the file ", { ...toastOptObj });
            console.error("Error occurs during sharing the file:", error);
        }
    };

    const onPhotoCheckUncheck = (checked: boolean | undefined, photoId: string): void => {
        if (checked !== undefined && typeof checked !== 'boolean') { return; }

        const updatedData = images.map((item: GetPhotosAPIResEntity) =>
            item.photoId === photoId ? { ...item, isChecked: (checked as boolean) } : item
        );
        setImages(updatedData);
    };

    const handleFilesOnClick = (url: string): void => {
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const bulkAction = async (feature: string): Promise<void> => {
        switch (feature) {
            case BulkActionsEntity.DOWNLOAD:
                const zip = new JSZip();
                const filteredArray: ImageNameAndURL[] = images.filter(item => item.isChecked).map(item => ({ filePath: item.filePath, displayFileName: item.displayFileName }));

                const worker = new Worker(new URL("../../../workers/zipWorker.ts", import.meta.url), { type: "module" });
                worker.postMessage({ images: filteredArray });

                worker.onmessage = (event: MessageEvent<{ success: boolean; zipBlob?: Blob; error?: string }>) => {
                    const { success, zipBlob, error } = event.data;

                    if (success && zipBlob) {
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(zipBlob);
                        if (IsNullOrUndefined(tagId) || IsStringNullEmptyOrUndefined(tagName)) {
                            link.download = (!IsNullOrUndefined(albumLocation) && !IsNullOrEmptyArray(albumLocation)) ? albumLocation.map(item => item.location).pop() ?? "images.zip" : "images.zip";
                        } else {
                            link.download = (!IsStringNullEmptyOrUndefined(tagName) ? tagName : "images.zip")
                        }
                        // link.download = albumLocation ? (albumLocation.split('/').pop()?.trim() || "images.zip") : "images.zip";
                        link.click();
                    } else {
                        toast.error("Failed to generate ZIP: " + (error || "Unknown error"), { ...toastOptObj });
                    }

                    worker.terminate();
                };
                break;

            case BulkActionsEntity.UPDATE_TAG:
                setIsTagUpdateDialogVisible(true);
                await fetchTags();
                break;

            case BulkActionsEntity.SHARE:
                try {
                    const filteredArray: ImageNameAndURL[] = images.filter(item => item.isChecked).map(item => ({
                        filePath: item.filePath,
                        displayFileName: item.displayFileName
                    }));
                    if (filteredArray.length > 10) {
                        toast.error("Max 10 Images are shareable", { ...toastOptObj });
                        return;
                    }
                    const filePromises = filteredArray.map((item: ImageNameAndURL) => getFileFromURL(item.filePath, item.displayFileName));
                    const files = await Promise.all(filePromises);
                    console.log("files:- ", files)

                    if (!navigator.canShare || !navigator.canShare({ files })) {
                        toast.error("This device/browser does not support file sharing.", { ...toastOptObj });
                        return;
                    }
                    await navigator.share({
                        files: files,
                    });

                    console.log("Files shared successfully!");
                } catch (error) {
                    toast.error("Error during sharing", { ...toastOptObj });
                    console.error("Error during sharing:", error);
                }
                break;

            case BulkActionsEntity.MOVE:
                setIsMoveImagesDialogVisible(true);
                await fetchAlbums();
                break;

            default:
                break;
        }
    }

    const markedChecked = (): number => {
        return images.filter((item: GetPhotosAPIResEntity) => item.isChecked).length;
    };

    const fetchTags = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetTagDDL();

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;

        if (axiosResp.statusCode !== 200 || !axiosResp.data) {
            toast.error(axiosResp.message || axiosResp.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        setTagsDDL(axiosResp.data.results)
        setIsLoading(false);
    };

    const fetchAlbums = async (): Promise<void> => {
        setIsLoading(true);
        const resp: AxiosResponse<APIResponseEntity> = await APIGetAlbumsDDL();

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        const axiosResp: APIResponseEntity = resp.data;

        if (axiosResp.statusCode !== 200 || !axiosResp.data) {
            toast.error(axiosResp.message || axiosResp.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        let respData: AlbumsDDL[] = axiosResp.data.data;
        let filteredAlbums = respData.filter(item => item.ids[item.ids.length - 1] !== paramAlbumId);
        setAlbumsDDL(filteredAlbums)
        setIsLoading(false);
    };

    const handleUpdateTag = async (): Promise<void> => {
        setIsLoading(true);

        const resp: AxiosResponse<APIResponseEntity> = await APIUpdateTagsOfMultiplePhotos({ tagIds: selectedTag, photosIds: images.filter(item => item.isChecked).map(item => item.photoId) });

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        const axiosResp: APIResponseEntity = resp.data;
        if (
            axiosResp &&
            axiosResp.data &&
            axiosResp.status &&
            axiosResp.statusCode === 200
        ) {
            toast.success(axiosResp.message, { ...toastOptObj });
            setIsLoading(false);
            setIsTagUpdateDialogVisible(false)
            setSelectedTag([]);
            await fetchAlbumPhotos(true);
        }

        else {
            const errorMessage = resp?.data?.message || "Failed to upload the file";
            toast.error(errorMessage, { ...toastOptObj });
            return;
        }

    };

    const handleMoveImages = async (): Promise<void> => {
        setIsLoading(true);
        const oldAlbumId: string = paramAlbumId ?? "";
        const newAlbumId: string = selectedAlbum?.ids.pop() ?? "";

        const photosIds: string[] = images.filter(item => item.isChecked).map((item) => item.photoId);

        const resp: AxiosResponse<APIResponseEntity> = await APIMoveAlbumsImages({ newAlbumId, oldAlbumId, photosIds });

        if (!resp || resp.status !== 200 || !resp.data) {
            toast.error(resp.data.message, { ...toastOptObj });
            setIsLoading(false);
            return;
        }
        const axiosResp: APIResponseEntity = resp.data;
        if (
            axiosResp &&
            axiosResp.data &&
            axiosResp.status &&
            axiosResp.statusCode === 200
        ) {
            toast.success(axiosResp.message, { ...toastOptObj });
            setIsLoading(false);
            setIsMoveImagesDialogVisible(false)
            setAlbumsDDL([]);
            setSelectedAlbum(null);
            await fetchAlbumPhotos(true);
        }

        else {
            const errorMessage = resp?.data?.message || "Failed to move images";
            toast.error(errorMessage, { ...toastOptObj });
            return;
        }

    };

    const checkHandoverImagePresent = (photos: GetPhotosAPIResEntity[] | []): boolean => {
        const handoverImages: GetPhotosAPIResEntity[] | [] = photos.filter((item: GetPhotosAPIResEntity) => item.isSystemConfigure)
        return IsNullOrEmptyArray(handoverImages) ? true : false
    }

    const resetCarousel = (): void => {

        const carouselElement = document.getElementById("carouselExample") as HTMLElement | null;
        if (carouselElement) {
            const carouselItems = carouselElement.querySelectorAll(".carousel-item");
            carouselItems.forEach((item) => {
                item.classList.remove("active");
            });

            setModelActiveImgIndex(-1);
        }
    };

    const createUpdateTagName = async (): Promise<void> => {
        setIsLoading(true);

        const resp: AxiosResponse<APIResponseEntity> = await APICreateUpdateTag({ tagName: valueTagName, id: '' });

        if (!resp || (resp && ((resp.status !== 201 && resp.status !== 200) || !resp.data))) {
            setIsLoading(false); return;
        }

        const apiRespPayload: APIResponseEntity = resp.data;

        if ((apiRespPayload.statusCode !== 201 && apiRespPayload.statusCode !== 200) || !apiRespPayload.data) {
            console.log(apiRespPayload);

            toast.error(apiRespPayload.message || apiRespPayload.errorMsg, { ...toastOptObj });
            setIsLoading(false);
            return;
        }

        toast.success(apiRespPayload.message, { ...toastOptObj });
        setValueTagName('');
        setIsOpenAddTag(false);
        await fetchTags();
        setIsLoading(false);
    };

    const isTagNameValid = (): boolean => {
        return !(IsStringNullEmptyOrUndefined(valueTagName) || valueTagName.trim().length === 0);
    };

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        setCheckedAll(false);
        scrollTop();
        setFilterReq((prev) => ({
            ...prev,
            pageSize: event.rows,
            pageNumber: event.page,
            APICallCount: prev.APICallCount! + 1,
        }));
    };


    const handleAlbumLocationRedirection = (locationData: albumLocationDetails[], index: number): void => {
        let albumIdsArray: string[] = locationData.slice(0, index + 1).map(item => item.id);
        navigate(`/jb-library/albums?albumIdsArray=${albumIdsArray}`)
    }


    return (
        <>
            <Loader isLoading={isLoading} />

            <div className="content-wrapper" id='scrollable-div'>
                <div className="row">
                    <div className="col-lg-12 col-md-12 mb-3">
                        <div className='mb-2'>
                            {(tagId && !IsNullOrUndefined(tagId) && !IsStringNullEmptyOrUndefined(tagId)) ?
                                <div>
                                    <span className='text-muted' >Tag Name:</span> <strong className='cursor-pointer' onClick={() => { navigate("/jb-library/tags") }}>{tagName}</strong>
                                </div> :
                                <div>
                                    {/* <span className='text-muted'>Album Location:</span> <strong>{""}</strong> */}
                                    <ol className='breadcrumb'>
                                        <li className='text-muted albumLocation' >
                                            Album Location:
                                        </li>
                                        {
                                            !IsNullOrEmptyArray(albumLocation) && albumLocation.map((item, index) => (
                                                <li
                                                    key={index}
                                                    className='breadcrumb-item cursor-pointer'
                                                    style={{ fontSize: "1rem" }}
                                                    onClick={() => handleAlbumLocationRedirection(albumLocation, index)}
                                                >
                                                    <strong>
                                                        {item.location}
                                                    </strong>
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </div>}
                        </div>

                        <div className="card card-rounded">
                            <div className="card-body">
                                <div className="col-lg-12 mb-3">
                                    <h4 className="card-title">Photos</h4>
                                </div>

                                <div className="col-lg-12 mb-3">
                                    <div className="row align-items-center">
                                        <div className="col-lg-3">
                                            {
                                                (
                                                    checkActionAuthorization("update", "Photos") ||
                                                    checkActionAuthorization("upgrade", "Access to download photos") ||
                                                    checkActionAuthorization("upgrade", "Access to share photos")
                                                ) && !IsNullOrEmptyArray(images) &&
                                                <>
                                                    <Checkbox onChange={e => setCheckedAll((prev) => !prev)}
                                                        checked={images.filter(item => item.isChecked).length === images.length && images.length != 0} />
                                                    <label className='ms-2'> Select All </label>
                                                </>
                                            }
                                        </div>
                                        {
                                            markedChecked() > 0 &&
                                            <div className="col-lg-9 text-end">
                                                {
                                                    checkActionAuthorization('upgrade', 'Access to move images') &&
                                                    IsNullOrUndefined(tagId) &&
                                                    <button type="button" className="btn btn-block btnShare me-2" onClick={() => { bulkAction(BulkActionsEntity.MOVE); }}>
                                                        {markedChecked() > 1 ? `Move Images(${markedChecked()})` : `Move Image(${markedChecked()})`}
                                                    </button>
                                                }
                                                {
                                                    checkActionAuthorization("update", "Photos") && checkHandoverImagePresent(images) &&
                                                    <button type="button" className="btn btn-block btnUpdatetag me-2" onClick={() => { bulkAction(BulkActionsEntity.UPDATE_TAG); }}>
                                                        {`Update Tag(${markedChecked()})`}
                                                    </button>
                                                }
                                                {
                                                    checkActionAuthorization("upgrade", "Access to download photos") &&
                                                    <button type="button" className="btn btn-block btnDownload me-2"
                                                        onClick={() => bulkAction(BulkActionsEntity.DOWNLOAD)}>
                                                        {`Download (${markedChecked()})`}
                                                    </button>
                                                }
                                                {
                                                    checkActionAuthorization("upgrade", "Access to share photos") &&
                                                    <button type="button" className="btn btn-block btnShare me-2" onClick={() => bulkAction(BulkActionsEntity.SHARE)}>
                                                        {`Share (${markedChecked()})`}
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </div>

                                    {
                                        checkActionAuthorization("upgrade", "Access to share photos") && images && !IsNullOrUndefined(images) && !IsNullOrEmptyArray(images.filter(item => item.isChecked)) && (images.filter(item => item.isChecked)).length > 10 &&
                                        <div className="d-flex justify-content-end mt-1">
                                            <span style={{ fontSize: "12px", fontWeight: "800" }}>
                                                Max 10 Images are shareable.
                                            </span>
                                        </div>
                                    }
                                </div>

                                <div className="col-lg-12">
                                    {IsNullOrEmptyArray(images) && (
                                        <div className="row">
                                            <p className='mb-0 font-weight-500'>No Images Available</p>
                                        </div>
                                    )}


                                    {
                                        !IsNullOrEmptyArray(images) &&
                                        <>
                                            <ul className="SearchresultWrapper ps-0">
                                                {
                                                    images.map((item: GetPhotosAPIResEntity, index: number) => (
                                                        <li key={index}>
                                                            <div className={`imgHldr ${item.isChecked ? 'active' : ''}`}>
                                                                <div className="checkBoxMain">
                                                                    {
                                                                        (
                                                                            checkActionAuthorization("update", "Photos") ||
                                                                            checkActionAuthorization("upgrade", "Access to download photos") ||
                                                                            checkActionAuthorization("upgrade", "Access to share photos")
                                                                        ) &&
                                                                        <>
                                                                            <Checkbox
                                                                                onChange={e => onPhotoCheckUncheck(e.checked, item.photoId)}
                                                                                checked={item.isChecked} />
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="imgTitle">
                                                                    <div className="downShareMain d-flex align-items-center">
                                                                        <div className="albums text-wrap flex-grow-1" style={{ wordBreak: 'break-word', minWidth: 0, fontSize: '16px' }}>
                                                                            {item.displayFileName}
                                                                        </div>
                                                                        <div className="flex-fill text-white">
                                                                            <div className="iconsHldr d-flex justify-content-end">
                                                                                {
                                                                                    checkActionAuthorization("upgrade", "Access to hide & show photos") &&
                                                                                    <img className={`me-1 ${item.isSystemConfigure ? "cursor-no-drop" : "cursor-pointer"}`}
                                                                                        src={`assets/images/library/${item.isActive ? 'eye.svg' : 'eye-slash.svg'}`}
                                                                                        alt="eye-slash" style={{ width: "1.2rem" }}
                                                                                        onClick={() => { if (!item.isSystemConfigure) { hideShowPhoto(item.photoId) } }} />
                                                                                }

                                                                                {
                                                                                    checkActionAuthorization("update", "Photos") &&
                                                                                    <img className={`me-1 ${item.isSystemConfigure ? "cursor-no-drop" : "cursor-pointer"}`} src="assets/images/library/edit.svg" alt="edit"
                                                                                        style={{ width: "1.2rem" }} onClick={() => {
                                                                                            if (!item.isSystemConfigure) {
                                                                                                setIsAddUpdateFileDialogVisible(true)
                                                                                                setDisplayName(item.displayFileName)
                                                                                                setCheckedAll(false);
                                                                                                setFilePath(item.filePath)
                                                                                                setPhotoId(item.photoId)
                                                                                                if (item.tagIds && !IsNullOrEmptyArray(item.tagIds)) {
                                                                                                    setTags(item.tagIds ?? []);
                                                                                                }
                                                                                            }
                                                                                        }} />
                                                                                }

                                                                                {
                                                                                    checkActionAuthorization("upgrade", "Access to download photos") &&
                                                                                    <img className="me-1 cursor-pointer" src="assets/images/library/import.svg" alt="import"
                                                                                        style={{ width: "1.2rem" }} onClick={() => { downloadPhoto(item.filePath, item.displayFileName) }} />
                                                                                }

                                                                                {
                                                                                    checkActionAuthorization("upgrade", "Access to share photos") &&
                                                                                    <img className="me-1 cursor-pointer" src="assets/images/library/export.svg" alt="export"
                                                                                        style={{ width: "1.2rem" }} onClick={() => { sharePhoto(item.filePath, item.displayFileName) }} />
                                                                                }

                                                                                {
                                                                                    checkActionAuthorization("delete", "Photos") &&
                                                                                    <img className={`me-1 ${item.isSystemConfigure ? "cursor-no-drop" : "cursor-pointer"}`} src="assets/images/library/delete.svg" alt="delete"
                                                                                        onClick={() => {
                                                                                            if (!item.isSystemConfigure) {
                                                                                                setDeletePhotoId(item.photoId);
                                                                                                setHideShowDeletePhotoConfirmation(true);
                                                                                                setCheckedAll(false);
                                                                                            }
                                                                                        }}
                                                                                        style={{ width: '1.2rem' }} />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <img src={item.filePath} alt={item.displayFileName} /> */}
                                                                {
                                                                    (albumsFileExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "")) &&
                                                                    <div
                                                                        className="exhibitionsImg"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#photosGalleryModel"
                                                                    >
                                                                        <img src={(item.filePath.includes('.gif')) ? item.filePath : item.bucketThumbnailImagePath} className='cursor-pointer' alt={item.displayFileName} loading="lazy" onClick={() => { setModelActiveImgIndex(index); }} />
                                                                    </div>
                                                                }
                                                                {
                                                                    AllowedVideoExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                                    <video controls className="w-100 h-75" onPlay={handleVideoPlay}>
                                                                        <source src={item.filePath} type={`video/${item.filePath.split(".").pop()?.toLowerCase()}`} />
                                                                        {/* Your browser does not support the video tag. */}
                                                                    </video>
                                                                }
                                                                {
                                                                    AllowedPDFExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src='assets/images/pdf-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ height: '75%', width: '50%' }} />
                                                                    </div>
                                                                }
                                                                {
                                                                    AllowedWordExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src='assets/images/word-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ height: '75%', width: '50%' }} />
                                                                    </div>
                                                                }
                                                                {
                                                                    AllowedExcelExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src='assets/images/excel-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ height: '75%', width: '50%' }} />
                                                                    </div>
                                                                }
                                                                {
                                                                    AllowedPPTExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src='assets/images/ppt-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ height: '75%', width: '50%' }} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            {
                                                !IsNullOrEmptyArray(images) &&
                                                <PrimePaginator
                                                    onPageChange={onPageChange}
                                                    pageNumber={filterReq.pageNumber}
                                                    pageSize={filterReq.pageSize}
                                                    totalRecords={totalPageCount}
                                                />
                                            }

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmDialog visible={hideShowDeletePhotoConfirmation} onHide={() => {
                setHideShowDeletePhotoConfirmation(false)
                setDeletePhotoId('')
            }} acceptClassName='p-button-danger'
                message="Do you want to delete this record?"
                header="Delete Confirmation" icon="pi pi-info-circle" accept={() => {
                    deletePhoto();
                }} />

            <Dialog visible={isTagUpdateDialogVisible} className="libraryModal" style={{ width: "550px", height: "600px", borderRadius: "4px" }}
                onHide={() => { setIsTagUpdateDialogVisible(false); setIsOpenAddTag(false); }} closable={false}>
                <img
                    className="albums-add-album mt-4"
                    src="assets/images/file-upload.svg"
                    alt="add-album"
                />
                <span
                    className="albums-add-album fw-bold mt-3 mb-3"
                    style={{ fontSize: "18px", color: "black" }}
                >
                    {markedChecked() === 1 ? "Edit tags for file" : "Edit tags for files"}
                </span>

                <div className="col-md-12 mb-3">
                    <label className="form-label">
                        TAG <sup className="mandatory">*</sup>
                    </label>
                    <div className="d-flex align-items-center">
                        {!isOpenAddTag &&
                            <>
                                <MultiSelect
                                    value={selectedTag}
                                    onChange={(e) => setSelectedTag(e.value)}
                                    options={tagsDDL}
                                    optionLabel="tagName"
                                    optionValue="tagId"
                                    placeholder="Select a Tag"
                                    className="w-100"
                                    filter
                                    showClear
                                    resetFilterOnHide
                                />
                                <button
                                    type="button"
                                    className="btn btn-success ms-2"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => setIsOpenAddTag(true)}
                                >
                                    Add Tag
                                </button>
                            </>
                        }
                        {
                            isOpenAddTag &&
                            <>
                                <div className='col-7 me-2'>
                                    <input type="text" className="form-control form-control-lg"
                                        onKeyDown={(event: any) => {
                                            checkValidInputOnKeyDown(event, ALPHABET_NUMBER_SPACE_PATTERN_DOT_ALLOW);
                                            removeSpaceOnFirstCharacter(event);
                                        }} maxLength={25} name="tagName" value={valueTagName} onChange={(e) => setValueTagName(e.target.value)} />
                                </div>
                                <div className='col-5 d-flex px-2'>
                                    <button type="submit" className="btn btn-block btn-cancel w-100 me-2" onClick={() => { setIsOpenAddTag(false); setValueTagName('') }}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-block btn-primary w-100"
                                        onClick={() => createUpdateTagName()}
                                        disabled={!isTagNameValid()}>
                                        Save
                                    </button>
                                </div>
                            </>
                        }
                    </div>
                </div>

                {
                    !IsNullOrEmptyArray(images) &&
                    <div className="col-lg-12 mb-3">
                        <ul className="row ps-0"
                            style={{
                                paddingTop: "10px",
                                //  height: "55vh", overflow: "auto"
                            }}>
                            {
                                images.filter(item => item.isChecked).map((item: GetPhotosAPIResEntity, index: number) => (
                                    <li
                                        key={index}
                                        className="col-4 d-flex justify-content-center mb-4"
                                    >
                                        <div className={`imgHldr ${item.isChecked ? 'active' : ''}`} style={{ width: "175px", height: "150px" }}>
                                            {/* <img
                                                src={item.filePath}
                                                alt={item.displayFileName}
                                                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                            /> */}
                                            {
                                                (albumsFileExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "")) &&
                                                <img src={item.filePath} alt={item.displayFileName} loading="lazy" style={{ width: "100%", height: "100%", objectFit: "cover" }} />

                                            }
                                            {
                                                AllowedVideoExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <video controls className="w-100 h-75" onPlay={handleVideoPlay}>
                                                    <source src={item.filePath} type={`video/${item.filePath.split(".").pop()?.toLowerCase()}`} />
                                                    {/* Your browser does not support the video tag. */}
                                                </video>

                                            }
                                            {
                                                AllowedPDFExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/pdf-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedWordExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/word-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedExcelExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/excel-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedPPTExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/ppt-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }

                <div className="row mt-4 modalBtn">
                    <div className="col-md-6 d-flex justify-content-end">
                        <Button
                            label="Cancel"
                            onClick={() => {
                                setIsTagUpdateDialogVisible(false);
                                setSelectedTag([]);
                                setIsOpenAddTag(false);
                            }}
                            className="cancelBtn w-100"
                        />
                    </div>
                    <div className="col-md-6 d-flex justify-content-start">
                        <Button className="addBtn w-100"
                            label="Update"
                            onClick={() => handleUpdateTag()}
                            disabled={IsNullOrEmptyArray(selectedTag)}
                        />
                    </div>
                </div>


            </Dialog>

            <AddUpdateFile
                fileName={"Edit a file to album"}
                displayName={displayName}
                filePath={filePath}
                folderId={paramAlbumId ?? ""}
                photoId={photoId}
                tags={tags}
                isAddUpdateFileDialogVisible={isAddUpdateFileDialogVisible}
                closeDialogCB={async (value) => {
                    setIsAddUpdateFileDialogVisible(!value);
                }}
            />

            <div className="conainer">
                <div className="modal fade" id="photosGalleryModel" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered modal-lg imgGallery-modal" style={{ marginTop: "25px", maxWidth: "900px" }}>
                        <div className="modal-content" >
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ fontSize: "1.25rem" }}>{albumLocation.map(item => item.location).join(" / ")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" style={{ padding: "10px" }} >
                                <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        {!IsNullOrEmptyArray(images) &&
                                            images.map((item: GetPhotosAPIResEntity, index: number) => (
                                                (albumsFileExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "")) &&
                                                <div className={index === modelActiveImgIndex ? "carousel-item active" : "carousel-item"}>
                                                    <div className="imgGalleryHldr">
                                                        <img src={item.filePath} alt="error" />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog visible={isMoveImagesDialogVisible} className="libraryModal" style={{ width: "550px", height: "550px", borderRadius: "4px" }}
                onHide={() => { setIsMoveImagesDialogVisible(false); }} closable={false} header={null} showHeader={false}>
                <img
                    className="albums-add-album mt-4"
                    src="assets/images/file-upload.svg"
                    alt="add-album"
                />
                <span
                    className="albums-add-album fw-bold mt-3 mb-3"
                    style={{ fontSize: "18px", color: "black" }}
                >
                    {markedChecked() === 1 ? "Move image to another album" : "Move images to another album"}
                </span>

                <div className="col-md-12 mb-3">
                    <label className="form-label">
                        ALBUM <sup className="mandatory">*</sup>
                    </label>
                    <div className="d-flex align-items-center albumDropdown">
                        <Dropdown
                            value={selectedAlbum}
                            className="w-100"
                            onChange={(e) => { setSelectedAlbum(e.value) }}
                            options={albumsDDL}
                            optionLabel="names"
                            placeholder="Select an album"
                            filter
                            showClear
                            resetFilterOnHide
                            pt={{
                                panel: { style: { width: "400px" } },
                                item: { style: { whiteSpace: "normal", wordWrap: "break-word", overflowWrap: "break-word" } },
                                input: {
                                    style: {
                                        display: "block",
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        overflowWrap: "break-word",
                                        maxWidth: "100%",
                                        height: "auto",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }
                                },
                                select: {
                                    style: {
                                        width: "100% !important",
                                        maxWidth: "300px",
                                    }
                                }
                            }}
                        />
                    </div>
                </div>

                {
                    !IsNullOrEmptyArray(images) &&
                    <div className="col-12 mb-3">
                        <ul className="row ps-0 moveImgModel" >
                            {
                                images.filter(item => item.isChecked).map((item: GetPhotosAPIResEntity, index: number) => (
                                    <li
                                        key={index}
                                        className="col-4 d-flex justify-content-center mb-4"
                                    >
                                        <div className={`imgHldr ${item.isChecked ? 'active' : ''}`} style={{ width: "175px", height: "150px" }}>
                                            {
                                                (albumsFileExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "")) &&
                                                <img src={(item.filePath.includes('.gif')) ? item.filePath : item.bucketThumbnailImagePath} alt={item.displayFileName}
                                                    loading="lazy" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedVideoExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <video controls className="w-100 h-75" onPlay={handleVideoPlay}>
                                                    <source src={item.filePath} type={`video/${item.filePath.split(".").pop()?.toLowerCase()}`} />
                                                    {/* Your browser does not support the video tag. */}
                                                </video>

                                            }
                                            {
                                                AllowedPDFExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/pdf-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedWordExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/word-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedExcelExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/excel-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                            {
                                                AllowedPPTExtension.includes(item.filePath.split(".").pop()?.toLowerCase() ?? "") &&
                                                <img src='assets/images/ppt-icon.svg' alt={item.displayFileName} loading="lazy" onClick={() => handleFilesOnClick(item.filePath)} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                                            }
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }

                <div className="row  modalBtn">
                    <div className="col-md-6 d-flex justify-content-end">
                        <Button
                            label="Cancel"
                            onClick={() => {
                                setIsMoveImagesDialogVisible(false);
                                setAlbumsDDL([]);
                                setSelectedAlbum(null);
                            }}
                            className="cancelBtn w-100"
                        />
                    </div>
                    <div className="col-md-6 d-flex justify-content-start">
                        <Button className="addBtn w-100"
                            label="Move"
                            onClick={() => handleMoveImages()}
                            disabled={IsNullOrUndefined(selectedAlbum)}
                        />
                    </div>
                </div>


            </Dialog>
        </>
    );
}

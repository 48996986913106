export enum LayOutNameEnum {
  WEBSITE_LAYOUT = "website_layout",
  AUTH_LAYOUT = "auth_layout",
}

export enum StorageKeyEnum {
  JB_GLASS_AUTH_TOKEN = "jb_glass_auth_token",
  JB_GLASS_USER_INFO = "jb_glass_user_info",
  JB_GLASS_REMEMBER_ME_DATA = "Jb_glass_rememberMeData",
  WALL_ORDER_FILTER = "wall_order_filter",
  MODEL_FILTER = "model_filter",
  USER_FILTER = "user_filter",
  WALTZ_FINISH_FILTER = "waltz_finish_filter",
  WALTZ_VARIANT_FILTER = "waltz_variant_filter",
  MODEL_TAB_FILTER = "model_tab_filter",
  GLASS_ORDER_STATUS_FILTER = "glass_order_status_filter",
  GLASS_ORDER_DETAILS = "glass_order_details",
  WALL_ORDER_DETAILS = "wall_order_details",
  USE_MAXIMUM_DISCOUNT = "maximum_discount",
  WALTZ_ORDERS_SOLUTIONS = "waltz_orders_solutions",
  UNDER_MAINTENANCE = "under_maintenance",
  EDITED_MODULES_FOR_PRICING_VERSION = "edited_modules_for_pricing_version",
  JB_GLASS_PAY_NOW_ORDERS_DUE = "jb_glass_pay_now_orders_dues",
  JB_GLASS_PAY_FOR_DRAFT_TRANSACTION = "jb_glass_pay_for_draft_transaction",
  JB_GLASS_PAYMENT_HISTORY_FILTER = "jb_glass_payment_history_filter",
  JB_GLASS_WHATSAPP_HISTORY_FILTER = "jb_glass_WHATSAPP_history_filter",
  JB_GLASS_PAYMENT_REFUND_HISTORY_FILTER = "jb_glass_payment_refund_history_filter",
  EDITED_MODULES_FOR_PERCENT_UPDATE = "edited_modules_for_percent_update",
  DIRECTOTY_FILTER_REQ = 'directory_filter_req',
  DIRECTOTY_FILTER_TYPE = 'directory_filter_type',
  TREE_STRUCTURE_FOR_ACCESSORIES_DETAIL = 'treeStructureForAccessoriesDetail',
  MEETING_FILTER = "meeting_filter",
}

export enum ModelsFileEnum {
  ORIENTATION_IMAGE_PATH = "orientationImagePath",
  SUB_ORIENTATION_REF_IMAGE_PATH = "subOrientationImagePath",
  PSE_IMAGE_PATH = "PSEImagePath",
  MEASUREMENT_SHEET_IMAGE_PATH = "measurementSheetImagePath",
}

export enum ActionEntityEnum {
  LIST = "list",
  VIEW = "view",
  UPDATE = "update",
  DELETE = "delete",
  CREATE = "create",
}

export enum GlassCount {
  ONE = "ONE",
  TWO = "TWO",
}

export enum IdReferenceType {
  PAN = "PAN",
  GST = "GST",
}

export enum TeamTypeEntity {
  SALES = "SALES",
  EXECUTION = "EXECUTION",
}

export enum OrderStatusEnumEntity {
  DRAFT = "draft",
  PIPELINE = "pipeline",
  EDITED = "edited",
  COMMERCIALHOLD = "commercial-hold",
  COMMERCIALWIN = "win",
  HANDOVER = "handover",
  OLDWIN = "old-win",
  ALL = "all",
  EXPIRED = "expired",
  LOST = "lost",
  PRECLOSURE = 'preclosure',
}

export enum OfficeTypeEnum {
  JBOFFICE = "JBOffice",
  DEALEROFFICE = 'dealerOffice'
}

export enum ArchitectDetailsValidationStatus {
  PENDING = "pending",
  APPROVED = "approved"
}

export enum ContactTypeEnumEntity {
  ARCHITECT = 1,
  CLIENT = 2,
  TRADER = 3
}

export enum VersionStatus {
  DRAFT = "draft",
  LIVE = "live",
}

export enum VisibleOrdersEnumEntity {
  ALL = 'all',
  SELF = 'self',
  SELF_OFFICE = 'selfOffice',
}

export enum UserProfileEnumEntity {
  ADMIN = "Admin",
  ACCOUNTANT = "Accountant",
  SALES_PERSON = "Sales person",
  SALES = "Sales",
  PROJECT_MANAGER = "Project manager",
}

export enum RegionEnum {
  DOMESTIC = "domestic",
  EXPORT = "export"
}

export enum OnlyGlassFinishFileEnum {
  IMAGE_THUMBNAIL_PATH = "imageThumbnailPath",
  IMAGE_ORIENTATION_PATH = "imageOrientationPath",
  IMAGE_POTRAIT_PATH = "imagePotraitPath",
  IMAGE_APPLICATION_PATH = "imageApplicationPath",
}

export enum GlassVariantsFileEnum {
  IMAGE_THUMBNAIL_PATH = "thumbnailImagePath",
  IMAGE_POTRAIT_PATH = "portraitImagePath",
  IMAGE_WALTZ_VARIANT_PATH = "imagePath",
}

export enum SettingModuleCategoryEnum {
  GENEAL_SETTINGS = "generalSettings",
  WALTZ_ORDER = "waltzOrder",
  ACCESSORIES = "accessories",
  GLASSCATALOGUES = "glass-catalogues"
}

export enum ValueMethodEnumEntity {
  VALUE = "VALUE",
  PERCENT = "PERCENT",
}

export enum CampaginSendThroughEntity {
  MAIL = "MAIL",
  WHATSAPP = "WHATSAPP",
  BOTH = "BOTH"
}

export enum CampaginShareEntity {
  SHARE_NOW = "SHARE_NOW",
  SCHEDULE_SHARE = "SCHEDULE_SHARE"
}

export enum CampaginArchitectListStatusEntity {
  ALL = 'ALL',
  SUCCESS = "SUCCESS",
  FAILED = 'FAILED'
}

export enum ProcoreProjectPDFLinksEnumEntity {
  GOODS_DELIVERY_NOTES_PDF = "goods_delivery_note_pdf",
  WORK_COMPLETION_SHEET_PDF = "work_completion_note_pdf",
  CUSTOMER_FEEDBACK_PDF = "customer_feedback_form_pdf",
  MEASUREMENT_SHEET_PDF = "measurement_sheet_pdf",
}

export enum MatteOptionEnumEntity {
  YES = "YES",
  NO = "NO",
}

export enum ConfirmationYesNoEnumEntity {
  YES = "YES",
  NO = "NO",
  NOT_APPLICABLE = "N/A"
}

export enum SecondaryFactorsEnum {
  WITH_IN = 'withIn',
  CROSS = 'cross',
  NA = 'na',
  EXPORT = 'export',
}

export enum PunchItemTypeEnum {
  SUBFRAME = "SUBFRAME",
  SUBTRACK = "SUBTRACK",
  NONE = "NONE"
}

export enum DelivaryAddressStatusEnum {
  DEALEROFFICE = "dealerOffice",
  PROJECT = "project"
}

export enum PaymentTransactionEnum {
  INITIATED = "initiated",
  INPROGRESS = "inprogress",
  SUCCESSFUL = "successful",
  FAILED = "failed",
  CASH_CHALLAN_GENERATED = 'cash_challan_generated',
  CHEQUE_CHALLAN_GENERATED = 'cheque_challan_generated',
  RTGS_CHALLAN_GENERATED = 'rtgs_challan_generated',
  NEFT_CHALLAN_GENERATED = 'neft_challan_generated',
  WIRE_CHALLAN_GENERATED = 'wire_challan_generated',
  PENDING_FOR_CHECKER_APPROVAL = 'pending_for_checker_approval',
  ICICI_SYSTEM_ERROR = 'icici_system_error',
  RIP = 'rip',
  SIP = 'sip',
  SETTLED = 'settled',
  UPDATE_MANUALLY = 'updated_manually',
  SCHEDULER_UPDATES = 'scheduler_updates',
}

export enum SolutionCategoryEnum {
  GLASS = 'GLASS',
  WALTZ = 'WALTZ'
}
export enum ArchitectDetailsRadioSelectionEnum {
  ARCHITECT_EXISTE = 'architect_exists',
  ARCHITECT_NOT_DISCLOSED = 'architect_not_disclosed'
}

export enum ArchitectUserType {
  PRINCIPAL = "PRINCIPAL",
  ASSOCIATE = "ASSOCIATE"
}

export enum SchedulerNameEnumEntity {
  SYNCED_PROCORE_PUNCH_ITEM_DUMP = "syncedProcorePunchItemDump"
}

export enum DirectoryStatusType {
  USER_TYPE = "USER_TYPE",
  CATEGORY = "CATEGORY"
}

export enum CampaignStatusEnumEntity {
  COMPLETED = "Completed",
  SCHEDULED = "Scheduled",
  IN_PROGRESS = "In Progress",
  DRAFT = "Draft",
  PENDING = "Pending",
}

export enum TeamStatusRedFlag {
  JARVIS_TEAM = "Jarvis Team",
  ACCOUNTS_TEAM = "Accounts Team",
  PROCORE_TEAM = "Procore Team",
  CS_PRODUCTION_SOFTWARE = "CS (Production Software)"
}

export enum ChargeByTypeEnumEntity {
  SQFT = "sqft",
  DIMENTION = "dimension"
}

export enum RibbonEnumEntity {
  RED_RIBBON = "Red Ribbon",
  COMMERCIAL_HOLD = "Commercial Hold",
  SELF_PERMISSION = "self"
}

export enum QtyTypeEnumEntity {
  RANGE = "range",
  DEFAULT = "default"
}

export enum GlassAccessory {
  SKIN = "SKIN",
  ACCESSORY = "ACCESSORY",
}

export enum ImportArchitectListTypeEnumEntity {
  ARCHITECT = 'architect',
  USER = 'user'
}

export enum ColorCodeEnumEntity {
  GREEN = "Green",
  RED = "Red",
  ORANGE = 'Orange',
  GREY = "Grey"
}

export enum ProLevelEnum {
  PRO = "PRO",
  PROPLUS = "PROPLUS",
  ARCHITECT = "Architect"
}
export enum BulkActionsEntity {
  UPDATE_TAG = "update-tag",
  DOWNLOAD = "download",
  SHARE = "share",
  MOVE = "move"
}

export enum UserSignInTrackEnumEntity {
  WEB_APP = "webapp",
  WEBSITE = "website"
}

export enum JbConnectContactSourceEnumEntity {
  SCAN = 'Scan',
  MANUAL = 'Manual',
  COPY_LINK = 'Copy Link',
  TRADER_BY_SOCIAL = 'Trader By Social'
}

export enum WhatsappQueueTypeEntity {
  CAMPAIGN = 'campaign',
  CAMPAIGN_TRAIL = 'campaign_trail',
  JB_CONNECT = "jb_connect",
  WEBHOOK_REPLY = 'webhook_reply',
  ORDER_WIN = 'order_win',
  MARCH_25_FOLLOWUP = 'MARCH_25_FOLLOWUP',
  MARCH_25_FOLLOWUP_ACTION_TAKEN = 'MARCH_25_FOLLOWUP_ACTION_TAKEN',
  EVENT = 'event'
}

export enum WhatsAppQueueStatusEnum {
  COMPELETED = "completed",
  IN_PROGRESS = "in_progress",
  FAILD = "faild",
  PENDING = "pending"
}

export enum WhatsappWebHookStatusEntity {
  DELIVERED = 'delivered',
  READ = 'read',
  FAILED = 'failed',
  SENT = 'sent'
}

export enum MeetingConfigurationCategoryEnumEntity {
  ARCHITECT = 'Architect',
  CLIENT = 'Client',
  TRADER = 'Trader'
}
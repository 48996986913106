import React from 'react'
import { PrimePaginatorPropsEntity } from '../interface';
import { Paginator, PaginatorCurrentPageReportOptions, PaginatorTemplateOptions } from 'primereact/paginator';
import { rowsPerPageOptions } from '../utils/constants';
import { Dropdown } from 'primereact/dropdown';

function PrimePaginator({ pageSize, pageNumber, totalRecords, onPageChange }: PrimePaginatorPropsEntity) {
    const template: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
        CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center', fontWeight: 500 }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <Dropdown value={options.value} options={rowsPerPageOptions} onChange={options.onChange} />;
        },
    };

    return (
        <Paginator first={(pageNumber * pageSize)}
            rows={pageSize}
            totalRecords={totalRecords}
            template={template}
            onPageChange={onPageChange}
        />
    )
}

export default PrimePaginator;